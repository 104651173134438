<template>
<teleport to="body">
<div class="modal fade modal_conclusion" id="modal-essais-clinique" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-hta">
    <div class="modal-content modal-content-hta">       
      
        <div class="modal-body modal-dialog-hta">
            <div class="custom-modal-header">
                <strong style="font-size:1rem;" v-if="!load"> {{$t('Clinical trials')}} </strong>
                <a class="icon-close-popup close-popup" data-bs-dismiss="modal" aria-label="Close" style="top: 10px; right: 10px;"></a>
            </div>         

            <EssaisClinique :datas="data_essais_clinique" /> 

            <div class="main-overlay" v-if="load" style="margin-top:10px; margin-bottom:0px;">
                <div class="loadding_circle" style="height:40px; width:40px;"></div>
            </div>
        </div>
        
    </div>
  </div>
</div>    
</teleport>
</template>

<script>

import EssaisClinique from './essais_clinique_detail.vue'
export default {
    name : "ModalEssaisClinique",
    components : {
        EssaisClinique
    },
    props : {
        id : {
            type : [String, Number],
            default : 0
        },
        agency : {
            type : String
        }
    },
    methods : {
        essais_clinique_popup(id, agency){
            let param = {}
            param.id = id
            param.agency = agency
            this.$store.dispatch("detail/get_data_essais_clinique", param)
        },
        set_drug_id(id){
            this.$store.dispatch("detail/set_drug_id", id)
        },
        set_agency(agency){
            this.$store.dispatch("detail/set_agency", agency)
        }
    },
    watch: {
        id(){
            this.set_drug_id(this.id)
            this.set_agency(this.agency)
            if(this.data_essais_clinique == null || this.data_essais_clinique == undefined){
                this.essais_clinique_popup(this.id, this.agency)
            }
        }
    },
    computed : {
        data_essais_clinique(){
            return this.$store.getters['detail/data_essais_clinique'];
        },
        load(){
            return this.$store.getters['detail/load'];
        }
    },
    
}
</script>

<style scope>
.modal-dialog-hta{max-width: 800px !important; top:2%; overflow-y: auto;}
.modal-content-hta {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 10px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
}
.modal-body b {
    color: #000;
    font-weight: 600;
}
.popup-indication p, .popup-indication div {
    display: inline;
}
.text-orange b {
    color :#f7941d;
}


</style>