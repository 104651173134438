<template>
    <div class="content-onglets table essais_clinique_detail"> 
        <template v-for="(data, main_key) in datas" :key="'main_key_'+main_key">
        <div :class="studies[main_key] == 1 ? 'box-active' : '' ">
            <!-- Part 1 Start -->
            <table class="comparateur2">   
                <tbody>
                    <tr>
                        <td style="width: 30%">
                            {{$t('essais_clinique.Trial name')}}<br>
                            <a v-if="data['trial_name'] || data['nct_id']" @click="toggle('studies', main_key)" style="cursor:pointer;">
                                <strong class="text-orange">{{data['trial_name']}}</strong>
                                <br>
                                <strong> {{check_empty(data['nct_id'])}} </strong>
                            </a>
                        </td>
                        <td :class="{'text-left' : data['official_title'] }">
                            <template v-if="data['official_title']">
                                <div v-for="(item_official_title, key_official_title) in data['official_title'].split(',')" v-html="item_official_title" :key="'official_title_'+key_official_title"></div>
                            </template>
                            <div v-else> - </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="tr-results" v-if="studies[main_key] == 1">
                <table class="comparateur2">
                    <thead>
                        <tr>            
                            <th style="width: 14%">{{$t('essais_clinique.Study phase')}}</th>
                            <th style="width: 14%">Trial details</th>
                            <th style="width: 14%">{{$t('essais_clinique.Number of patients')}}</th>
                            <th style="width: 14%">{{$t('Status')}}</th>
                            <th style="width: 14%">Intervention</th>
                            <th style="width: 14%">Type</th>
                            <th style="width: 14%">Comparaison</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>            
                            <td>
                                {{check_empty(data['phase'])}}
                            </td>
                            <td>
                                {{check_empty(data['allocation'])}}<br>
                                {{check_empty(data['masking'])}}
                            </td> 
                            <td>{{check_empty(data['enrollment'])}}</td>
                            <td>{{check_empty(data['overall_status'])}}</td>
                            <td>
                                <template v-for="(item, key) in data.intervention" :key="'inventions_'+key">
                                    <div v-html="item['name']"></div>
                                </template>
                            </td>
                            <td>{{check_empty_with_locale(data['ec_type'])}}</td>
                            <td>{{check_empty(data['ec_comparateur'])}}</td>

                        </tr>
                    </tbody>
                </table>
                <!-- Part 1 End -->

                <!-- Part 2 Start -->
                <template v-if="data.outcome.length > 0">
                    <template v-for="(item_outcome, key_outcome) in data.outcome" :key="'key_outcome_'+key_outcome">
                        <table class="comparateur2">
                            <thead>
                                <tr>            
                                    <th style="width: 75%;" class="text-left text-orange">
                                        <a @click="toggle('outcomes', key_outcome)">
                                            <em :class="outcomes[key_outcome] == 1 ? 'icon-minus-s' : 'icon-plus-s'" style="vertical-align: middle; "></em>
                                        </a> 
                                        {{check_empty(item_outcome['outcome_type'])}} Endpoint
                                    </th>
                                    <th style="width: 25%">Units</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>            
                                    <td class="text-left">{{check_empty(item_outcome['title'])}}</td>
                                    <td>{{check_empty(item_outcome['units'])}}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="tr-results" :key="'outcome_measurement_main'+key_outcome" v-if="outcomes[key_outcome] == 1">

                            <table class="comparateur2 prismheor-table-th-gray">
                                <thead>
                                    <tr>
                                        <th>Arm</th>
                                        <th>Results</th>
                                        <th>CI 95 % min</th>
                                        <th>CI 95% max</th>
                                    </tr>                                                            
                                </thead>  
                                <tbody>
                                    <template v-for="(item_measure, key_measure) in item_outcome['outcome_measurement']" :key="'key_measure_tr_1_'+key_measure">
                                        <tr>  
                                            <td colspan="4" style="font-weight: bold; text-align: left" v-if="item_measure['category'] || item_measure['classification']"> 
                                                {{item_measure['category']}} {{item_measure['classification']}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{check_empty(item_measure['arm'])}}</td>
                                            <td>{{check_empty(item_measure['param_value'])}}</td>
                                            <td>{{check_empty(item_measure['dispersion_lower_limit'])}}</td>
                                            <td>{{check_empty(item_measure['dispersion_upper_limit'])}}</td>
                                        </tr> 
                                    </template>
                                </tbody>   
                            </table>
                            <!-- Part 2 End -->
                                    
                            <!-- Part 3 Start -->
                            <table class="comparateur2" v-if="item_outcome['outcome_analyse'].length > 0">
                                <thead>
                                    <tr>
                                        <th style="width: 35%">Group</th>
                                        <!-- <th style="width: 12%">Effect size and significance</th>                     -->
                                        <th style="width: 8%">RR/HR/OR Type</th>
                                        <th style="width: 8%">RR/HR/OR</th>
                                        <th style="width: 8%">CI 95 % min</th>
                                        <th style="width: 8%">CI min</th>
                                        <th style="width: 8%">CI max</th>
                                        <th style="width: 8%">P Value</th>
                                    </tr>                                                            
                                </thead>  
                                <tbody>
                                    <tr v-for="(item_analyse, key_analyse) in item_outcome['outcome_analyse']" :key="'key_analyse_'+key_analyse">
                                        <td>{{check_empty(item_analyse['groups'])}}</td>
                                        <!-- <td>{{check_empty(item_analyse['effect_size'])}}</td> -->
                                        <td>{{check_empty(item_analyse['param_type'])}}</td>
                                        <td>{{check_empty(item_analyse['param_value'])}}</td>
                                        <td>
                                            <template v-if="item_analyse['ci_lower_limit'] && item_analyse['ci_upper_limit']">{{item_analyse['ci_lower_limit']}} to {{item_analyse['ci_upper_limit']}}</template>
                                            <template v-else> - </template>
                                        </td>
                                        <td>{{check_empty(item_analyse['ci_lower_limit'])}}</td>
                                        <td>{{check_empty(item_analyse['ci_upper_limit'])}}</td>
                                        <td>{{check_empty(item_analyse['p_value'])}}</td>
                                    </tr> 
                                </tbody>   
                            </table>
                        </div>
                    </template>
                </template>
                <!-- Part 3 End -->

                <!-- Part 4 Start -->
                <template v-else-if="data['design_outcome'].length > 0">
                    <table class="comparateur2" v-for="(item_design_outcome, key_design_outcome) in data['design_outcome']" :key="'key_design_outcome_'+key_design_outcome">
                        <thead>
                            <tr>            
                                <th style="width: 75%;" class="text-left text-orange">
                                    {{check_empty(capitalize(item_design_outcome['outcome_type']))}} Endpoint
                                </th>
                                <th style="width: 25%">Units</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>            
                                <td class="text-left">{{check_empty(item_design_outcome['title']) }}</td>
                                <td> - </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <!-- Part 4 End -->
                
                        
            </div>
        </div>
        </template>
    </div> 
</template>

<script>

import { check_empty, capitalize, check_empty_with_locale } from '../../utils'
export default {
    name : 'EssaisClinique',
    data() {
        return {
            studies : {},
            outcomes : {},
            design_outcomes : {}
        }
    },
    props : {
        datas : {
            style : Array
        },
    },
    watch : {
        datas(){
            for(let key in this.datas){
                this.studies[key] = 0
                if(this.datas[key].outcome.length > 0)
                {
                    for(let key1 in this.datas[key].outcome){
                        this.outcomes[key1] = 0
                    }
                }
                if(this.datas[key].design_outcome)
                {
                    for(let key2 in this.datas[key].design_outcome){
                        this.design_outcomes[key2] = 0
                    }
                }
            }
        }
    },
    methods : {
        check_empty,
        capitalize,
        check_empty_with_locale,
        toggle(main, key){
            if(this[main][key] == 1){
                this[main][key] = 0
            }else{
                this[main][key] = 1
            }
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped>
.icon-plus-s {
    position: static;
}

.icon-minus-s {
    display: inline-block;
    position: static;
}
</style>